import Glide from '@glidejs/glide';

export function glideFrontpage() {
    let glide = new Glide('.glide', {
        type: 'carousel',
        startAt: 0,
        perView: 1,
        autoplay: 2000,
        hoverpause: false,
        peek: {
            before: 100,
            after: 100
        }
    });
    glide.mount();
}
export async function glideFrontpageApi() {
  try {
    const response = await fetch('/api/records?q=custom_fields.izpostavljeno:true');
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();

    // Get the slides container
    const slidesContainer = document.getElementById('slides');
    // Iterate over the data and create slides
    // Filter records that have custom_fields.izpostavljeno = true 
    //const filteredRecords = data.hits.hits.filter(record => record.custom_fields.izpostavljeno === true);
    data.hits.hits.forEach(record => {
      // Create a list item for each slide
      const slide = document.createElement('li');
      slide.className = 'glide__slide';
      const uiContainer = document.createElement('div');
      uiContainer.className = 'ui container';
      const uiCard = document.createElement('div');
      uiCard.className = 'ui card';
      const slideContent = document.createElement('div');
      slideContent.className = 'slide content';
      // Create the title and description elements
      const title = document.createElement('h2');
      title.textContent = record.metadata.title;  // Assuming the API returns an object with a 'title' property
      const description = document.createElement('div'); // Use a div to hold the HTML content 
      description.innerHTML = record.metadata.description; // Set innerHTML to include HTML
      const filesEnabled = document.createElement('div');
      filesEnabled.textContent = record.files.enabled;
      if (record.files.enabled == true) {
        console.log("files:enabled!");
      }

      // Append title and description to the slide
      //slide.appendChild(title);
      //slide.appendChild(description);
      //slide.appendChild(filesEnabled);
      // Append slide to the slides container
      slidesContainer.appendChild(slide);
      slide.appendChild(uiContainer);
      uiContainer.appendChild(uiCard);
      uiCard.appendChild(slideContent);
      slideContent.appendChild(title);
      slideContent.appendChild(description);
    });

    let glide = new Glide('.glide', {
      type: 'carousel',
      startAt: 0,
      perView: 1,
      //autoplay: 2000,
      autoplay: false,
      hoverpause: false,
      /*peek: {
        before: 100,
        after: 100
      }*/
    });
    glide.mount();
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}
/*
async function fetchImage() {
  const recordId = result.id;
  const filesEnabled = result.files.enabled;
  const apiUrl = `/api/records/${recordId}/files`;

  if (filesEnabled) {
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.enabled) {
        const imageEntry = data.entries.find(entry => entry.mimetype.startsWith('image/'));
        if (imageEntry) {
          let imageUrl = imageEntry.links.iiif_api;
          imageUrl = imageUrl.replace(/\/full\/full\//, '/full/!250,250/');
          document.getElementById('image').src = imageUrl; // Assuming you have an image element with id 'image'
        } else {
          // No image entry found, use placeholder
          document.getElementById('image').src = placeholderImage;
        }
      } else {
        // API is not enabled, use placeholder
        document.getElementById('image').src = placeholderImage;
      }
    } catch (error) {
      console.error("Error fetching image:", error);
      document.getElementById('image').src = placeholderImage;
    }
  }
}*/